
    import { defineComponent, ref, watch, onMounted } from 'vue';

    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import ApiService from '@/services/ApiService';
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";
    import { getGrupos, getMarcas } from "@/services/OnePageReportGrupoService";

    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';

    export default defineComponent({
        name: "filtro-onepage-report-grupo",
        components: {
            FilterBase,
            ElConfigProvider,
        },

        setup(props, { emit }) {
            const store = useStore();
            const dataInicialPeriodo = moment().startOf("month").toISOString();
            const codConcessionaria:any = ref("");
            const codGrupo:any = ref("");
            const codMarca:any = ref("");
            const optionsGrupos:any = ref([]);
            const optionsMarcas:any = ref([]);

            const datePicker = ref(dataInicialPeriodo);

            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();

            const activeModal = ref(false);
            const closeModal = ref(false);
            const disabledClick = ref(false);

            const loading = ref(false);
            const modalFiltro:any = ref();
            const showClose = ref(false);


            onMounted(async () => {
                if(store.getters.concessionariaSelectedInfo){
                    codConcessionaria.value = store.getters.concessionariaSelectedInfo.codConcessionaria;
                    codGrupo.value = store.getters.concessionariaSelectedInfo.codGrupo;
                }

            });

            store.watch(() => store.getters.concessionariaSelectedInfo, async() => {
                codConcessionaria.value = store.getters.concessionariaSelectedInfo.codConcessionaria;
                codGrupo.value = store.getters.concessionariaSelectedInfo.codGrupo;
                await trataGrupos();

            }, { deep: true });

            async function trataGrupos() {
                loading.value = true;
                const grupos = await getGrupos();
                loading.value = false;

                const auxGrupos = grupos.map(grupo => {
                    return {
                        text: grupo.nome,
                        value: grupo.codGrupo.toString(),
                    }
                });
                optionsGrupos.value = auxGrupos;

                // Inicia filtro e emite dados iniciais
                if(optionsGrupos.value.length >= 1){
                    if(codGrupo.value){
                        await trataMarcas();
                    } else {
                        activeModal.value = !activeModal.value;
                        showClose.value = false;
                        codGrupo.value = optionsGrupos.value[0].codGrupo;
                        await trataMarcas();
                    }
                } else {
                    codGrupo.value = optionsGrupos.value[0].codGrupo;
                    await trataMarcas();
                }
            };

            async function trataMarcas(emiteMudancaDefault = true){
                loading.value = true;
                codMarca.value = "";
                const marcas = await getMarcas(codGrupo.value);
                loading.value = false;

                const auxMarcas = marcas.map(marca => {
                    return {
                        text: marca.nomeMarca,
                        value: marca.codMarca.toString(),
                        content: marca
                    }
                });
                optionsMarcas.value = auxMarcas;

                // Inicia filtro e emite dados iniciais
                if(optionsMarcas.value.length > 1){
                    if(codMarca.value){
                        if(emiteMudancaDefault)
                        emiteMudanca(); 
                    } else {
                        // activeModal.value = !activeModal.value;
                        // showClose.value = false;
                        codMarca.value = optionsMarcas.value[0].value;
                        if(emiteMudancaDefault)
                        emiteMudanca(); 
                    }
                } else {
                    codMarca.value = optionsMarcas.value[0].value;
                    emiteMudanca(); 
                }
            }

            function desabilitaDatasAnteriores (data){
                const dataAnterior = data.getTime() > new Date("2016-01-01T00:00:00") === data.getTime() > Date.now()
                return dataAnterior
            };


            function retornaFiltroData(dataInicial, dataFinal){
                dataInicial = moment(dataInicial).format();
                dataFinal = moment(dataFinal).endOf('month').format();
                dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
                dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

                const filters = `${dataInicial}/${dataFinal}`;
                
                return filters;
            };

            async function emiteMudanca(){
                console.log("emite mudança")
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();

                const dataSelecionada = {
                    ano: moment(datePicker.value).format("YYYY"),
                    mes: moment(datePicker.value).format("MM"),
                }

                if(codMarca.value){
                    let auxMarca = optionsMarcas.value.find(option => option.value == codMarca.value);
                    auxMarca = {
                        ...auxMarca.content
                    }
                    
                    const marca = auxMarca;
                    
                    emitter.emit("filtrar-one-page-report-marca", { ...dataSelecionada, marca });
                }
            };

            function teste(a, b){
                console.log(a);
                console.log(b);
                console.log("codMarca", codMarca.value);
            }

            return {
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                desabilitaDatasAnteriores,
                optionsGrupos,
                optionsMarcas,
                codGrupo,
                codMarca,
                trataMarcas,
                disabledClick,
                teste
            };
        }
    });
